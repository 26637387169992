import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchQuestions = async (params = {}) => {
    try {
        const response = await axios.post(`${API_URL}/api/questions`, params);
        console.log('questions', response.data);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the questions!', error);
        throw error;
    }
};
