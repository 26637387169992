import React, { useState } from 'react';
import './App.css';
import QuestionsTable from './components/QuestionsTable';
import Login from './components/Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <QuestionsTable setIsAuthenticated={setIsAuthenticated}/>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
